<template>    
    <div style="height:480px; width:inherit">
        <l-map ref="map" id="map" v-model:zoom="zoom" :maxZoom="maxZoom" v-on:click="addMarker" :minZoom="minZoom" :center="center" >
            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base" name="OpenStreetMap2">   
            </l-tile-layer>
            <l-marker  v-for="marker in marcadores" :lat-lng="marker" :key="marker"  >
                <l-popup >
                    <div class="row" >
                        <div class="col-md-12">
                            <div class="input-group">
                                {{ marker.descripcion }}
                                <button type="button" @click="removeMarker(marker)"  class="btn btn-block btn-outline-danger btn-xs" >{{ $t('general.eliminar') }}</button>
                            </div>
                        </div>
                    </div>
                </l-popup>
            </l-marker>
        </l-map>
    </div>
</template>
<script>
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import { PwgsApi } from "../../../../services/PwgsApi";
import { LMap, LTileLayer,LMarker,LPopup/*LIcon*/} from "@vue-leaflet/vue-leaflet";
export default {
    name: "Map",
    props: ['empresa', 'aniadir','base'],
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup
        //LIcon,
    },
    data() {
        return {
            center: [0,0],
            map: '',
            zoom: 13,
            iconWidth: 25,
            iconHeight: 40,
            maxZoom: 19,
            minZoom: 2,
            markers: [],
            idaux: { id: '' },
            marcadores: [],
        };
    },
    methods: {
        async removeMarker(coordenadas) {
            let claves = Object.keys(this.marcadores);
            const api = new PwgsApi;
            for (var i = 0; i <= claves.length; i++) { 
                if (this.marcadores[i].lat == coordenadas.lat && this.marcadores[i].lng == coordenadas.lng && this.marcadores[i].indice) { 
                    // DELETE  modulos/pwgsapi/index.php/empresa/coordenadas/:id
                    await api.delete('empresa/1/coordenadas/' + this.marcadores[i].indice);
                    this.$parent.$parent.obtenerEmpresa();
                    this.cargarmarcadores();
                }
            }
        },
        async addMarker(event) {
            const api = new PwgsApi;
            let text = event.latlng.lat.toString() + "," + event.latlng.lng.toString();
            let subidadatos = { principal: " ", descripcion: " ", coordenadas: text };
            if (this.$props.aniadir) {
                await api.post('empresa/1/coordenadas', subidadatos);
                this.$parent.$parent.obtenerEmpresa();
                this.cargarmarcadores();
            }
            if (this.$props.base) {
                subidadatos.descripcion = "Principal";
                await api.post('empresa/1/coordenadas', subidadatos);
              this.$parent.$parent.obtenerEmpresa();
              this.cargarmarcadores();
            }
        },
        cargarmarcadores() { 
            setTimeout(() => {
                let claves = Object.keys(this.empresa);
                for (var i = 0; i <= claves.length; i++) {
                    let clave = claves[i];
                    for (var j = 0; j <= this.empresa[clave].coordenadas.length; j++) {
                        if (this.empresa[clave].coordenadas[j] == ",") {
                            if (this.empresa[clave].descripcion == "Principal") {
                                this.center = [this.empresa[clave].coordenadas.slice(0, j), this.empresa[clave].coordenadas.slice(j + 1)]
                            }
                            this.markers[i]=(L.latLng(this.empresa[clave].coordenadas.slice(0, j), this.empresa[clave].coordenadas.slice(j + 1)));
                            this.markers[i].indice = this.empresa[clave].id;
                            this.markers[i].descripcion = this.empresa[clave].descripcion;
                            this.marcadores = this.markers;
                        }
                    }
                }
            }, 400);
        }
    },
    computed: {
        iconUrl() {
            return `https://placekitten.com/${this.iconWidth}/${this.iconHeight}`;
        },
        iconSize() {
            return [this.iconWidth, this.iconHeight];
        },

    },
    mounted(){
            this.cargarmarcadores();   
    },
    watch: {
        empresa() {
             this.cargarmarcadores();
        },

    }
};
//https://github.com/vue-leaflet/vue-leaflet
</script>